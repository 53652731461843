import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
// import { ServiceService } from '../service.service';

@Component({
  selector: 'app-trade-details',
  templateUrl: './trade-details.component.html',
  styleUrls: ['./trade-details.component.css']
})
export class TradeDetailsComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef
  tradeId: any;
  tradeDetails: any;
  chatHistory: any = [];

  itemsPerPage: number = 5;
  currentPage: number = 1
  totalItems: number;

  tradeList: any = [];
  fkUserId: any;
  partnerId: any;
  
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: MainService) {
    this.activatedRoute.params.subscribe((res) => {
      console.log(res);
      if (res.id) {
        this.tradeId = res.id
      }
    })
  }

  ngOnInit() {
    this.getTradeDetails();
    this.getTradeList()
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 100)
  }

  getTradeDetails() {
    this.service.showSpinner();
    this.service.get(`p2p-exchange/admin/get-trade-details-by-tradeid?tradeId=${this.tradeId}`).subscribe((res) => {
      console.log(res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.tradeDetails = res['data'].RESULT_LIST
        this.fkUserId = res['data'].RESULT_LIST.fkUserId;
        this.partnerId = res['data'].RESULT_LIST.partnerId;
        this.getChatHistory()
      }
    }, err => {
      this.service.hideSpinner()
    })
  }

  getChatHistory() {
    this.service.showSpinner();
    let url = `notification/get-chat-history-through-admin?tradeId=${this.tradeId}&adminId=${this.fkUserId}&userId=${this.partnerId}`
    // this.service.get(`notification/get-chat-data-for-admin?tradeId=${this.tradeId}`).subscribe((res) => {
      this.service.get(url).subscribe((res) => {  
    console.log(res)
      this.service.hideSpinner();
      if (res['status'] == 1620) {
        this.chatHistory = res['data']
        console.log(this.chatHistory)
        this.scrollToBottom()
      }
    }, err => {
      this.service.hideSpinner()
    })
  }

  openDispute() {
    this.service.showSpinner();
    this.service.get(`p2p-exchange/after-press-dispute-button?disputeStatus=Raised&tradeId=${this.tradeId}`).subscribe((res) => {
      console.log(res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        this.router.navigate['/trade-management']
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.toasterErr(err['message'])
      this.service.hideSpinner()
    })
  }

   // get trade history list 
   getTradeList() {
    let url = `p2p-exchange/search-and-filters-trade-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.tradeList = res.data.list
        this.totalItems = res.data.totalCount;
      } else {
        this.tradeList = []
        this.totalItems = 0
      }
    }, err => {
      this.service.hideSpinner();
      console.log(err)
      this.tradeList = []
      this.totalItems = 0
    })
  }

   // pagination
   pagination(page) {
    this.currentPage = page;
    console.log(this.currentPage)
    this.getTradeList()
  }
}
