import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
declare var $: any

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  pageNumber: number = 1;
  // totalItems: any;
  beDisable: boolean = true;
  userForm: FormGroup;
  userID: any;
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  newArray: any = [];
  newFromDate: number;
  newToDate: any;
  exportList = []
  // pagination
  itemsPerPage: number = 15;
  currentPage: number = 1;
  totalItems: number;

  // search
  id: any;
  email: string;
  userName: string;
  status: any = '';
  country: any = '';
  phoneNo: any;

  countryList: any[] = []

  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'sedan': new FormControl(''),
      'hatch': new FormControl(''),
      'suv': new FormControl(''),
    })
    // this.onDateChanged();
    this.getUserList();
    this.getCountryList()
  }

  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }

  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    }
  }

  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/

  // get country list
  getCountryList() {
    this.service.get('account/get-country-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.countryList = res['data']
      }
    })
  }


  // -------------- Get List of User -------------------- //
  getUserList() {
    // var url = "account/admin/user-management/filter-user-details";
    var url = `account/admin/user-management/filter-user-details?page=${(this.currentPage - 1) + '&pageSize=' + this.itemsPerPage
      + (this.id ? ('&id=' + this.id) : '') + (this.email ? ('&email=' + this.email) : '') + (this.userName ? ('&userName=' + this.userName) : '')
      + (this.status ? ('&status=' + this.status) : '') + (this.country ? ('&country=' + this.country) : '') +
      (this.phoneNo ? ('&phoneNo=' + (this.phoneNo.startsWith('+') ? (this.phoneNo.replace(/^\+/, '%2B')) : ('%2B' + this.phoneNo))) : '')}`

    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log("UserLIST", res)
      if (res['status'] == 200) {
        this.userList = res['data']['list'];
        this.totalItems = res.data.totalCount;
        this.service.hideSpinner();
      } else {
        this.userList = [];
        this.totalItems = 0;
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.userList = [];
        this.totalItems = 0;
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  // search
  search() {
    if (this.id || this.email || this.userName || this.status || this.country || this.phoneNo) {
      this.currentPage = 1
      this.getUserList()
    } else {
      console.log("Please select field for search.")
    }
  }

  // reset search
  reset() {
    if (this.id || this.email || this.userName || this.status || this.country || this.phoneNo) {
      this.currentPage = 1
      this.id = ''
      this.email = ''
      this.userName = '';
      this.status = '';
      this.country = ''
      this.phoneNo = ''
      this.getUserList()
    }
  }

  // pagination
  pagination(page) {
    this.currentPage = page;
    console.log(this.currentPage)
    this.getUserList()
  }

  // Apply Filter
  applyFilter(val) {
    if (val == 'date') {
      var startdate = this.userForm.value.startdate.epoc ? (Number(this.userForm.value.startdate.epoc) * 1000) : ''
      var enddate = this.userForm.value.enddate.epoc ? (Number(this.userForm.value.enddate.epoc) * 1000) : ''
      var url = "account/admin/user-management/filter-user-details?fromDate=" + startdate + "&page=" + (this.pageNumber - 1) + "&toDate=" + enddate + "&pageSize=10";
    } else {
      var url = "account/admin/user-management/filter-user-details?&page=" + (this.pageNumber - 1) + "&search=" + this.userForm.value.searchText + "&pageSize=10";
    }
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data']['list'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  // Delete / Block Function
  openModal(action, userId) {
    this.userID = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  //User Details
  // userdetails(userId, email) {
  //   console.log("fgsdgsdf",userId);
  //   this.router.navigate(['/user-details/'], {queryParams: {userId,email}} )
  // }
  userdetails(data) {
    console.log("fgsdgsdf", data);
    this.router.navigate(['/user-details/'], { queryParams: { id: data } })
  }

  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {
      dataArr.push({
        "Sl no": ind + 1,
        "User ID": element.userId ? element.userId : '',
        "User Name": element.firstName + ' ' + element.lastName ? element.firstName + ' ' + element.lastName : 'NA',
        "Email": element.email ? element.email : 'N/A',
        // "Mobile Number": element.phoneNo ? element.phoneNo : 'N/A',
        "Status": element.userStatus ? element.userStatus : 'N/A',
        "Registration Date & Time": element.createTime ? element.createTime.slice(0, 10) : 'N/A',
      })
    })
    this.service.exportAsExcelFile(dataArr, 'User list');
  }

  // Delete User
  deleteFunction() {
    var url = 'account/admin/user-management/delete-user-detail?userIdToDelete=' + (this.userID) + '&ipAddress=' + (6754675467) + '&location=' + ('unknown');
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User deleted successfully.');
        this.getUserList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  performAction() {
    var url = 'account/admin/user-management/user-status?ipAddress=' + (11) + '&location=' + ('unknown') + '&userIdForStatusUpdate=' + (this.userID) + '&userStatus=' + (this.action);
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'BLOCK') {
          $('#block').modal('hide');
          this.service.toasterSucc('User blocked successfully.');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('User activated successfully.');
        }
        this.getUserList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  // searchTe() {
  //   this.service.showSpinner()
  //   this.service.get('account/admin/user-management/filter-user-details?search=' + (this.userForm.value.searchText ? this.userForm.value.searchText : '')).subscribe((res: any) => {
  //     console.log("TextSearch", res)
  //     this.service.hideSpinner()
  //     if (res.status == 200) {
  //       this.userList = res['data']['list'];
  //     }
  //     else {
  //       this.userList = res['data']['list'];
  //     }

  //   })
  // }

  // reset() {
  //   this.userForm.reset();
  //   this.getUserList();
  // }

  // searchByDate() {
  //   this.newFromDate = Math.round(new Date(this.userForm.value.startdate).getTime())
  //   this.newToDate = Math.round(new Date(this.userForm.value.enddate).getTime())
  //   console.log("newFromDate", this.newFromDate)
  //   console.log("newFromDate", this.newToDate)
  //   console.log("newFromDate", this.userForm.value.startdate.epoc)
  //   console.log("newFromDate", this.userForm.value.enddate.epoc)
  //   this.service.showSpinner()
  //   this.service.get('account/admin/user-management/filter-user-details?fromDate=' + (this.userForm.value.startdate.epoc * 1000) + '&toDate=' + (this.userForm.value.enddate.epoc * 1000)).subscribe((res: any) => {
  //     this.service.hideSpinner()
  //     console.log("hjgvjhgds", res)
  //     this.userList = res['data']['list'];
  //   })
  // }

  // ---------------- navigate to admin management ---------------- //
  goAdmin() {
    this.router.navigate(['/admin-management'])
  }

}
