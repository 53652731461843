<main class="middle-content">
  <div class="page_title_block">
      <h1 class="page_title" style="color:black">User Management</h1>
  </div>

  <div class="content-section">
      <div class="outer-box">
          <div class="global-table no-radius p0">
              <div class="tab-content1">
                  <div class="tab-pane1">
                      <div class="user-main-head">

                          <div class="row">

                              <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select" style="margin-top: 20px;">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center"> </span>
                                          <input type="text" placeholder="ID" class="form-control"
                                              (keypress)="service.restrictSpace($event)" [(ngModel)]="id"
                                              style="height: 38px;">
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select" style="margin-top: 20px;">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center"> </span>
                                          <input type="text" placeholder="Email" class="form-control"
                                              (keypress)="service.restrictSpace($event)" [(ngModel)]="email"
                                              style="height: 38px;">
                                      </div>
                                  </div>
                              </div>
                              <!-- <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center">Start Date</span>
                                          <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}" name="formdate"
                                              [(ngModel)]="calender.formdate" (change)="formdate()"
                                              class="form-control datepicker" placeholder="Search by date">
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center">End Date</span>
                                          <input type="date" name="todate" min="{{fromDate | date:'yyyy-MM-dd'}}"
                                              (change)="todate()" [disabled]="!calender.formdate"
                                              [(ngModel)]="calender.todate" class="form-control datepicker"
                                              placeholder="Search by date">
                                      </div>
                                  </div>
                              </div> -->
                              <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select" style="margin-top: 20px;">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center"> </span>
                                          <input type="text" placeholder="First Name" class="form-control"
                                              (keypress)="service.restrictSpace($event)" [(ngModel)]="userName"
                                              style="height: 38px;">
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select" style="margin-top: 20px;">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center"></span>
                                          <select class="form-control" [(ngModel)]="status">
                                              <option value="">Account Status</option>
                                              <option value="UNVERIFIED">Unverified</option>
                                              <option value="ACTIVE">Active</option>
                                              <option value="BLOCK">Block</option>
                                              <!-- <option value="DELETED">Deleted</option> -->
                                              <option value="SUSPENDED">Suspended</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>

                              <!-- <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select" style="margin-top: 20px;">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center"> </span>
                                          <select class="form-control" [(ngModel)]="country">
                                              <option value="">Country</option>
                                              <option *ngFor="let item of countryList" [value]="item?.country">
                                                  {{item?.country}}</option>
                                          </select>
                                      </div>
                                  </div>
                              </div> -->
                              <!-- <div class="col-md-2" style="padding-left: 0px;">
                                  <div class="head_flt_select" style="margin-top: 20px;">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center"> </span>
                                          <input type="text" placeholder="Mobile Number" class="form-control"
                                              (keypress)="service.restrictSpace($event)" [(ngModel)]="phoneNo"
                                              style="height: 38px;">
                                      </div>
                                  </div>
                              </div> -->
                              <div class="col-md-4" style="padding-left: 0px;">
                                <div class="head_flt_select" style="margin-top: 20px;">
                                    <button type="submit" (click)="search()" class="btn  btn-theme mr-2">Search</button>
                            <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>
                            <button type="submit" class="btn  btn-theme" (click)="exportAsXLSX()">EXPORT
                                AS EXCEL
                            </button>
                                </div>
                            </div>
                            <!-- <button type="submit" (click)="search()" class="btn  btn-theme mr-2">Search</button>
                            <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button> -->
                          </div>

                          <!-- next row -->
                          <!-- <div class="row mt-4">
                              <div class="col-md-12 user-pl">
                                  <div class="user-frmbtn text-right" style=" width: 100%;">
                                      <button type="submit" (click)="search()"
                                          class="btn  btn-theme mr-2">Search</button>
                                      <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>
                                      <button type="submit" class="btn  btn-theme" (click)="exportAsXLSX()">EXPORT
                                          AS EXCEL
                                      </button>
                                  </div>
                              </div>
                              <div class="col-md-8">
                                  <div class="tras-threebtn" style="float: right;">

                                      <div class="comman-btn">
                                          <button type="submit" class="btn  btn-theme" (click)="exportAsXLSX()">EXPORT
                                              AS EXCEL
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div> -->
                      </div>

                      <div class="table-responsive">
                          <table class="table table-bordered" aria-describedby="user details table">
                              <thead>
                                  <tr class="no_wrap_th">
                                      <th scope="col">S.No</th>
                                      <th scope="col">ID</th>
                                      <th scope="col">User Name</th>
                                      <th scope="col">Email</th>
                                      <!-- <th scope="col">Unique ID</th> -->
                                      <th scope="col">Mobile Number</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Registration Date & Time</th>
                                      <th class="action_td_btn3" scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <!-- <tr
                                      *ngFor="let data of userList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems:totalItems}; let i=index; "> -->
                                  <tr
                                      *ngFor="let data of userList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems:totalItems}; let i=index; ">
                                      <!-- <td>{{10 * (pageNumber - 1) + i+1}}</td> -->
                                      <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                      <td>{{data?.userId}}</td>
                                      <td>{{data?.firstName}} {{data?.lastName}}</td>
                                      <td>{{data?.email || '---'}}</td>
                                      <!-- <td>{{data?.randomId}}</td> -->
                                      <td>{{data?.phoneNo || '---'}}</td>
                                      <td *ngIf="data?.userStatus == 'BLOCK'"><span class="badge badge-danger"
                                              style="font-size: 12px;">Blocked</span></td>
                                      <td *ngIf="data?.userStatus == 'ACTIVE'"><span class="badge badge-success"
                                              style="font-size: 12px;">Active</span></td>
                                      <td *ngIf="data?.userStatus == 'UNVERIFIED'"><span class="badge badge-warning"
                                              style="font-size: 12px;">Unverified</span></td>
                                      <td *ngIf="data?.userStatus == 'VERIFIED'"><span class="badge badge-info"
                                              style="font-size: 12px;">Verified</span></td>
                                      <td *ngIf="data?.userStatus == 'SUSPENDED'"><span class="badge badge-info"
                                        style="font-size: 12px;">Suspended</span></td>
                                      <!-- "UNVERIFIED"<td><button type="submit" class="btn btn-theme">{{data?.userStatus}}</button></td> -->
                                      <td>{{data?.createTime | date:'medium'}}</td>
                                      <td class="action_td_btn3" style="cursor: pointer">
                                          <!-- <a><em class="fa fa-eye"
                                                  (click)="userdetails(data?.userId, data?.email)"></em></a> -->
                                          <a><em class="fa fa-eye" (click)="userdetails(data?.userId)"></em></a>
                                          <a><em class="fa fa-trash"
                                                  (click)="openModal('DELETE',data?.userId)"></em></a>
                                          <a *ngIf="data?.userStatus == 'ACTIVE'" data-toggle="modal"
                                              (click)="openModal('BLOCK',data?.userId)"><em
                                                  class="fa fa-ban text-success"></em></a>
                                          <a *ngIf="data?.userStatus == 'BLOCK'" data-toggle="modal"
                                              (click)="openModal('ACTIVE',data?.userId)"><em
                                                  class="fa fa-ban text-danger"></em></a>
                                          <a *ngIf="data?.userStatus == 'UNVERIFIED'" data-toggle="modal">
                                              <em class="fa fa-times text-danger border border-warning"></em></a>
                                      </td>
                                  </tr>
                                  <tr *ngIf="userList == ''">
                                      <td colspan="8"> No user found</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <!-- <div class="custom-pagination mt20 text-right" *ngIf="userList.length > 10 ">
                          <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                      </div> -->
                      <div class="custom-pagination mt20 text-right" *ngIf="totalItems > 10 ">
                          <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

</main>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
  <div class="modal-dialog max-WT-500">
      <form class="change_password">
          <div class="modal-content">
              <div>
                  <div class="modal-body">
                      <h3 style="text-align: center;font-size: 25px; ">Remove User </h3>
                      <hr>
                      <button type="button" class="close" data-dismiss="modal"
                          style="margin-top: -85px;">&times;</button>
                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                          <div class="w-100">
                              <p style="font-size: 20px;">Are you sure you want to remove this user?</p>
                              <div>
                                  <button type="submit" class="btn btn-info mr-2"
                                      (click)="deleteFunction()">Yes</button>
                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
  <div class="modal-dialog max-WT-500">
      <form class="change_password">
          <div class="modal-content">
              <div>
                  <div class="modal-body">
                      <h3 style="text-align: center;font-size: 25px; ">Block User </h3>
                      <hr>
                      <button type="button" class="close" data-dismiss="modal"
                          style="margin-top: -85px;">&times;</button>
                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                          <div class="w-100">
                              <p style="font-size: 20px;">Are you sure you want to block this user?</p>
                              <div>
                                  <button type="submit" class="btn btn-success mr-2"
                                      (click)="performAction()">Yes</button>
                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>
<!-- active Start -->

<div class="modal fade global-modal reset-modal" id="active">
  <div class="modal-dialog max-WT-500">
      <form class="change_password">
          <div class="modal-content">
              <div>
                  <div class="modal-body">
                      <h3 style="text-align: center;font-size: 25px; ">Active User </h3>
                      <hr>
                      <button type="button" class="close" data-dismiss="modal"
                          style="margin-top: -85px;">&times;</button>
                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                          <div class="w-100">
                              <p style="font-size: 20px;">Are you sure you want to active this user?</p>
                              <div>
                                  <button type="submit" class="btn btn-success mr-2"
                                      (click)="performAction()">Yes</button>
                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>
