import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { MyDatePickerModule } from 'mydatepicker';
import { CKEditorModule } from 'ngx-ckeditor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// component
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidemenuComponent } from './pages/sidemenu/sidemenu.component';
import { FooterComponent } from './pages/footer/footer.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { AdvertisementManagementComponent } from './pages/advertisement-management/advertisement-management.component';
import { AdvertisementDetailsComponent } from './pages/advertisement-details/advertisement-details.component';
import { KycManagementComponent } from './pages/kyc-management/kyc-management.component';
import { TradeManagementComponent } from './pages/trade-management/trade-management.component';
import { TradeDetailsComponent } from './pages/trade-details/trade-details.component';
import { DisputeManagementComponent } from './pages/dispute-management/dispute-management.component';

import { DatePipe } from '@angular/common';
import { AdminManagementComponent } from './pages/admin-management/admin-management.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { AddAdminComponent } from './pages/add-admin/add-admin.component';
import { ViewAdminComponent } from './pages/view-admin/view-admin.component';
import { KycActionPageComponent } from './pages/kyc-action-page/kyc-action-page.component';
import { FinancesComponent } from './pages/finances/finances.component';
import { EditAdminComponent } from './pages/edit-admin/edit-admin.component';
import { FeeManagementComponent } from './pages/fee-management/fee-management.component';
import { StaticContentComponent } from './pages/static-content/static-content.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermAndServiceComponent } from './pages/term-and-service/term-and-service.component';
import { AuthGuard } from './guard/auth.guard';
import { SettingComponent } from './pages/setting/setting.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { DisputeTradeDetailsComponent } from './pages/dispute-trade-details/dispute-trade-details.component';
import { HotcoldwalletManagementComponent } from './pages/hotcoldwallet-management/hotcoldwallet-management.component';
import { ReferralManagementComponent } from './pages/referral-management/referral-management.component';
import { ReferralDetailsComponent } from './pages/referral-details/referral-details.component';
import { ReferralSettingComponent } from './pages/referral-setting/referral-setting.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NotificationManagementComponent } from './pages/notification-management/notification-management.component';
import { ViewNotificationComponent } from './pages/view-notification/view-notification.component';
import { AddNotificationComponent } from './pages/add-notification/add-notification.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    SidemenuComponent,
    FooterComponent,
    UserManagementComponent,
    AdvertisementManagementComponent,
    AdvertisementDetailsComponent,
    KycManagementComponent,
    TradeManagementComponent,
    TradeDetailsComponent,
    DisputeManagementComponent,
    AdminManagementComponent,
    UserDetailsComponent,
    AddAdminComponent,
    ViewAdminComponent,
    KycActionPageComponent,
    FinancesComponent,
    EditAdminComponent,
    FeeManagementComponent,
    StaticContentComponent,
    AboutUsComponent,
    PrivacyComponent,
    TermAndServiceComponent,
    SettingComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    EditProfileComponent,
    DisputeTradeDetailsComponent,
    HotcoldwalletManagementComponent,
    ReferralManagementComponent,
    ReferralDetailsComponent,
    ReferralSettingComponent,
    ResetPasswordComponent,
    NotificationManagementComponent,
    ViewNotificationComponent,
    AddNotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    NgxPaginationModule,
    MyDatePickerModule,
    CKEditorModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [DatePipe, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
