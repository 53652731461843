<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Notification Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tras-threebtn" style="float: right;">
                                                <div class="comman-btn">
                                                    <button type="submit" class="btn  btn-theme"
                                                        (click)="addNotification()">Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No</th>
                                        <th id="">Id</th>
                                        <th id="">Description</th>
                                        <th>Created At</th>
                                        <th class="action_td_btn3" id="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of notificationList | paginate:{itemsPerPage: itemsPerPage, currentPage:currentPage ,totalItems: totalItems}; let i=index; ">
                                        <td> {{itemsPerPage * (currentPage-1)+ i+1}}</td>
                                        <td>{{data?.notificationId}}</td>
                                        <td class="word-rap">{{data?.message}}</td>
                                        <td>{{data?.createdAt | date:'medium'}}</td>
                                        <td class="action_td_btn3" style="cursor: pointer">
                                            <a><em class="fa fa-eye" (click)="viewNotification(data?.brodcastId)"></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="notificationList?.length == 0">
                                        <td colspan="5"> No Data Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-right" *ngIf="totalItems > 10 ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
