import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
// import { filter } from 'minimatch';
import { MainService } from 'src/app/provider/main.service';
declare var $: any

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userListLength: any;
  kycpendingListlength: any;
  activeUserLength: any;
  blockedUserLength: any;
  pendingUserLength: any
  coinList: any = [];
  countByKycStatus: any;
  totalUserCount: any;
  advDisputeFeeCount: any;

  constructor(
    private service: MainService,
    private router: Router,
    // private toastr: ToastrManager,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
  ) {

  }

  ngOnInit() {
    // this.sidemenu();
    this.getUserList();
    this.getAllCoins();
    // this.getListOfKyc();
    // this.getAllCoins()
    // this.getCountDetails();
    this.getAdvertisementDisputeFeeCount()
  }
  // http://182.72.203.244:3062/account/admin/dashboard/dashboardApi

  // getCountDetails() {
  //   this.service.get('account/admin/dashboard/dashboardApi').subscribe((res) => {
  //    
  //   })
  // }

  // Get List of User
  getUserList() {
    var url = "account/admin/dashboard/dashboardApi";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var userList = res['data'][0];
        this.activeUserLength = userList.activeUserCount;
        this.blockedUserLength = userList.blockUserCount;
        this.countByKycStatus = userList.countByKycStatus;
        this.pendingUserLength = userList.pendingUserCount;
        this.totalUserCount = userList.totalUserCount;
        this.userListLength = this.activeUserLength + this.blockedUserLength + this.pendingUserLength;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  // getListOFKYC Function
  getListOfKyc() {

    var url = 'account/admin/dashboard/pendingKycCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.kycpendingListlength = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }


  // Get All The Coin Functionality
  getAllCoins() {
    this.service.showSpinner();
    // this.service.get('/wallet/admin/dashboard/get-deposit-and-coin-count').subscribe(res => {
    this.service.get('wallet/admin/get-deposit-and-coin-count').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinList = res['data'];
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }



  // sidemenu() {
  //   $(".btn-toggle,.close_panel").click(function () {
  //     $("body").toggleClass("toggle-wrapper");
  //   });
  // }

  // get advertisement, dispute and fee collection count
  getAdvertisementDisputeFeeCount() {
    var url = "p2p-exchange/admin/get-p2p-count-for-dashboard";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
     
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.advDisputeFeeCount = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

}
