<main class="middle-content" >
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">User Details</h1>

    </div>
    <!-- Page Title End -->
    <div class="content-section" >
        <div class="outer-box" >
            <div class="global-table common-tabs">

                <div class="tab-content" >
                    <div class="tab-pane active show" id="btc">
                        <div class="tab-pane1">
                            <div class="row  form-group">
                                <div class="col-md-12">

                                    <div class="tras-threebtn" style="float: right;">
                                        <div class="comman-btn">
                                            <button *ngIf="userStatusForSuspend == 'SUSPENDED'" type="submit"
                                                class="btn  btn-theme" (click)="unsuspenduser()">Unsuspend
                                                User
                                            </button>
                                            <button *ngIf="userStatusForSuspend == 'ACTIVE'" type="submit"
                                                class="btn  btn-theme" (click)="suspenduser()">Suspend
                                                User
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                <div class="form-group row">

                                  <div class="col-md-6" style="margin-top: -3%;">
                                    <div>
                                        <label style="font-weight: 600;">KYC Status</label>
                                    </div>
                                    <table class="table table-bordered" aria-describedby="">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th id="">Document</th>
                                                <th id="">Status</th>
                                                <th id="">Date - Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Mobile Number</td>
                                                <td>
                                                    <button *ngIf="basicUserDetails?.phoneNo!=' ' && basicUserDetails?.phoneNo!='+string' &&  basicUserDetails?.phoneNo"
                                                        class="btn-sm btn-theme" disabled
                                                        style="font-size: 12px!important">VERIFIED</button>
                                                    <button *ngIf="!basicUserDetails?.phoneNo || basicUserDetails?.phoneNo == '+string' || basicUserDetails?.phoneNo==''"
                                                        class="btn-sm btn-theme" disabled
                                                        style="font-size: 12px!important">UNVERIFIED</button>
                                                </td>
                                                <td>{{(basicUserDetails?.kyc?.updateTime | date:'medium') || "--"}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>Email</td>
                                                <td>
                                                    <button *ngIf="userStatusForSuspend == 'ACTIVE'"
                                                        class="btn-sm btn-theme" disabled
                                                        style="font-size: 12px!important">VERIFIED</button>
                                                    <button *ngIf="userStatusForSuspend == 'BLOCK'"
                                                        class="btn-sm btn-theme" disabled
                                                        style="font-size: 12px!important">UNVERIFIED</button>
                                                </td>
                                                <td>{{basicUserDetails?.kyc?.updateTime | date:'medium'}}</td>
                                            </tr> -->
                                            <tr>
                                                <td>KYC</td>
                                                <td>
                                                    <button *ngIf="basicUserDetails?.kyc?.kycStatus != 'ACCEPTED'" class="btn-sm btn-theme" disabled
                                                        style="font-size: 12px!important">UNVERIFIED</button>
                                                    <button *ngIf="basicUserDetails?.kyc?.kycStatus == 'ACCEPTED'" class="btn-sm btn-theme" disabled
                                                        style="font-size: 12px!important">ACCEPTED</button>
                                                </td>
                                                <td>{{basicUserDetails?.kyc?.updateTime | date:'medium'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                    <div class="col-md-6">
                                      <div class="card" style="margin-top: -1%;">
                                        <div style="margin-left: 10%;margin-top:10px">
                                      <div class="form-group row">
                                        <div class="col-md-4 edit-text ">Username</div>
                                        <div class="col-md-1">:</div>
                                        <div class="col-md-7">{{basicUserDetails?.firstName}}</div>
                                    </div>

                                            <div class="form-group row">
                                              <div class="col-md-4 edit-text ">Email</div>
                                              <div class="col-md-1">:</div>
                                              <div class="col-md-7">{{basicUserDetails?.email}}</div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-md-4 edit-text ">Account Created</div>
                                            <div class="col-md-1">:</div>
                                            <div class="col-md-7">{{basicUserDetails?.createTime | date:'medium'}}</div>
                                        </div>

                                        </div>
                                    </div>
                                    </div>



                            </div>




                            <!-- tab section -->
                            <div class="custom_tabs common-tabs" style="margin-top: 30px;" >
                                <div class="row mb20 justify-content-center">
                                    <div class="col-sm-12">
                                        <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='advertisement'}"
                                                    (click)="selectTab('advertisement')"
                                                    href="javascript:;">Advertisement</a>
                                            </li>
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='trades'}"
                                                    (click)="selectTab('trades')" href="javascript:;">Trades</a>
                                            </li>
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='wallet'}"
                                                    (click)="selectTab('wallet')" href="javascript:;">Wallet</a>
                                            </li>

                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='blockedby'}"
                                                    (click)="selectTab('blockedby')" href="javascript:;">Blocked By
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>



                                <div class="content-section">


                                    <div class="tab-pane active show" *ngIf="this.currTab == 'advertisement'">
                                        <div class="">

                                            <table class="table table-bordered"
                                                aria-describedby="advertisement details table">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Ad ID</th>
                                                        <th scope="col">Created Date & Time</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Payment Method</th>
                                                        <th scope="col">Fiat Currency</th>
                                                        <th scope="col">Max Value</th>
                                                        <th scope="col">Min Value</th>
                                                        <th scope="col">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of advertisementlist | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: advertisementlistlength}; let i=index; ">
                                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                        <td>{{data?.peerToPeerExchangeId}}</td>
                                                        <td>{{data?.creationTime | date:'medium'}}</td>
                                                        <td><button class="btn btn-theme"
                                                                disabled>{{data?.orderStatus | titlecase}}</button></td>
                                                        <td>{{data?.orderType}}</td>
                                                        <td>{{data?.paymentType}}</td>
                                                        <td>{{data?.fiatCoin}}</td>
                                                        <td>{{data?.maxValue}}</td>
                                                        <td>{{data?.minValue}}</td>
                                                        <td>{{data?.price}}</td>
                                                    </tr>
                                                    <tr *ngIf="advertisementlist.length==0">
                                                        <td colspan="9" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <h5>No record found</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </div>

                                    
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'trades'">
                                        <div class="">

                                            <table class="table table-bordered" aria-describedby="trade details table">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Ad ID</th>
                                                        <th scope="col">Created Date & Time</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Payment Method</th>
                                                        <th scope="col">Trading Partner</th>
                                                        <th scope="col">Fiat</th>
                                                        <th scope="col">Trade Amount</th>
                                                        <th scope="col">Fee</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of tradeList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: tradeListlength}; let i=index; ">
                                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                        <td>{{data?.tradingId}}</td>
                                                        <td>{{data?.creationTime | date:'medium'}}</td>
                                                        <td><button class="btn btn-theme"
                                                                disabled>{{data?.tradeStatus | titlecase}}</button></td>
                                                        <td>{{data?.type}}</td>
                                                        <td>{{data?.paymentType}}</td>
                                                        <td>{{data?.buyer || '---'}}</td>
                                                        <td>{{data?.totalBTC}} BTC</td>
                                                        <td>{{data?.tradeAmount}}</td>
                                                        <td>{{data?.tradeFee}}</td>
                                                    </tr>
                                                    <tr *ngIf="tradeList==0">
                                                        <td colspan="9" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <h5>No record found</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="tradeListlength > 5 ">
                                                <pagination-controls (pageChange)="pagination($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'wallet'">
                                        <div class="">

                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No</th>
                                                        <th id="">Coin</th>
                                                        <th id="">Transaction Type</th>
                                                        <th id="">Date</th>
                                                        <th id="">Transaction Hash</th>
                                                        <th id="">Amount</th>
                                                        <th id="">Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of wallethistoryData | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: wallethistoryDatalength}; let i=index; ">
                                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                        <td>{{data?.coinType}}</td>
                                                        <td>{{data?.txnType}}</td>
                                                        <td>{{data?.txnTime | date}}</td>
                                                        <td>{{data?.txnHash || '---'}}</td>
                                                        <td>{{data?.units}}</td>
                                                        <td>{{data?.userEmail}}</td>
                                                    </tr>
                                                    <tr *ngIf="wallethistoryData==0">
                                                        <td colspan="7" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <h5>No record found</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="wallethistoryDatalength > 10 ">
                                                <pagination-controls (pageChange)="currentPage = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'feedback'">
                                        <div class="">
                                            <!-- <div class="input-group filter_search_group">
                                                <input type="text" class="form-control" style="max-width: 85%;"
                                                    placeholder="Search bar text-filter">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button" style="width: 33%;
                                                    height: 87%;"><img src="assets/img/icon-search.png"
                                                            alt="Search"></button>&nbsp;
                                                    <button class="btn btn-theme" style="float: right">Export In
                                                        Excel</button>
                                                </div>
                                            </div>&nbsp; -->
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No.</th>
                                                        <th id="">Date</th>
                                                        <th id="">Type</th>
                                                        <th id="">Feedback</th>
                                                        <th id="">Customer Name</th>
                                                        <th id="">Message</th>
                                                        <th id="">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- <tr feedbackLoopData> -->
                                                    <tr
                                                        *ngFor="let data of feedbackLoopData | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: advertisementlistlength}; let i=index; ">
                                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                        <!-- <td>1</td> -->
                                                        <td>{{data?.date | date}}</td>
                                                        <td>{{data?.type}}</td>
                                                        <td><button class="btn btn-theme"
                                                                disabled>{{data?.feedbackStatus | titlecase}}</button></td>
                                                        <td>{{data?.name}}</td>
                                                        <td>{{data?.feedbackMessage}}</td>
                                                        <td class="action_td_btn3" style="cursor: pointer">
                                                            <a (click)="feedback(data?.feedbackId)"><em
                                                                    class="fa fa-eye"></em></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="feedbackLoopData==0">
                                                        <td colspan="7" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <h5>No record found</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="advertisementlistlength > 5 ">
                                                <pagination-controls (pageChange)="currentPage = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'trustedby'">
                                        <div class="">
                                            <!-- <div class="input-group filter_search_group">
                                                <input type="text" class="form-control" style="max-width: 85%;"
                                                    placeholder="Search bar text-filter">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button" style="width: 33%;
                                                    height: 87%;"><img src="assets/img/icon-search.png"
                                                            alt="Search"></button>&nbsp;
                                                    <button class="btn btn-theme" style="float: right">Export In
                                                        Excel</button>
                                                </div>

                                            </div>&nbsp; -->
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No.</th>
                                                        <th id="">Date-Time</th>
                                                        <th id="">Username</th>
                                                        <th id="">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>03/07/2020, 01:45 PM</td>
                                                        <td>Username</td>
                                                        <td class="action_td_btn3" style="cursor: pointer">
                                                            <a (click)="blockby('1')"><em class="fa fa-trash"></em></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="staffList?.length==0">
                                                        <td colspan="5" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <h5>No record found</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="    float: right;">

                                                <pagination-controls (pageChange)="pagination($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'blockedby'">
                                        <div class="">
                                            <!-- <div class="input-group filter_search_group">
                                                <input type="text" class="form-control" style="max-width: 85%;"
                                                    placeholder="Search bar text-filter">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button" style="width: 33%;
                                                            height: 87%;"><img src="assets/img/icon-search.png"
                                                            alt="Search"></button>&nbsp;
                                                    <button class="btn btn-theme" style="float: right">Export In
                                                        Excel</button>
                                                </div>

                                            </div>&nbsp; -->
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No</th>
                                                        <th id="">Date-Time</th>
                                                        <th id="">User Name</th>
                                                        <th id="">Blocked By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of blockedbyData | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: advertisementlistlength}; let i=index; ">
                                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                        <td>{{data?.dateTime | date}}</td>
                                                        <td>{{data?.userName}}</td>
                                                        <td class="action_td_btn3" style="cursor: pointer">
                                                            <a (click)="blockby(data?.blockedId)"><em
                                                                    class="fa fa-trash"></em></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="blockedbyData==0">
                                                        <td colspan="5" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <h5>No record found</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!-- <div class="custom-pagination mt20 text-center" style="    float: right;">

                                                <pagination-controls (pageChange)="pagination($event)">
                                                </pagination-controls>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-pagination mt20 text-center" style="float: right;"
                                    *ngIf="advertisementlistlength > 5 ">
                                    <pagination-controls (pageChange)="currentPage = $event">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Remove User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="deleteFunction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="suspendModal">
    <div class="modal-dialog max-WT-500">
        <form [formGroup]="suspendForm" class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; font-size: 25px;">Suspend User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="form-group row">
                            <div class="col-md-4" style="text-align: center;">
                                <label>Reason :</label>
                            </div>
                            <div class="col-md-8">
                                <textarea name="" id="" style="padding: 2%; width: 220px;"
                                    formControlName="reasonforsuspend" rows="5"></textarea>
                                <div class="mt-3" >
                                    <button class="btn btn-theme" style="margin-left: 0px;"
                                        (click)="finalSuspendUser()">Suspend</button>&nbsp;&nbsp;
                                    <button class="btn btn-theme" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="unsuspendModal">
    <div class="modal-dialog max-WT-500">
        <form [formGroup]="suspendForm" class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; font-size: 25px;">Unsuspend User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>

                        <div class="form-group row">
                            <div class="col-md-4" style="    text-align: center;">
                                <label>Reason :</label>
                            </div>
                            <div class="col-md-8">
                                <textarea name="" id="" style="padding: 2%; width: 220px;"
                                    formControlName="reasonforsuspend" rows="5"></textarea>
                                <div class="mt-3" >
                                    <button class="btn btn-theme" style="margin-left: 0px;"
                                        (click)="finalUnuspendUser()">Unsuspend</button>&nbsp;&nbsp;
                                    <button class="btn btn-theme" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
