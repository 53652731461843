import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css']
})
export class StaticContentComponent implements OnInit {

  staticList: any=[];

  constructor(public service:MainService) { }

  ngOnInit() {
    this.getListCode();
    //this.sidemenu();
  }
  //https://p2p-java.mobiloitte.com/static/get-static-content?pageKey=About%20Us
 // https://p2p-java.mobiloitte.com/static/admin/static-content/get-all-static-content
   // Get List Code
   getListCode(){
    this.service.showSpinner();
    this.service.get('static/admin/static-content/get-all-static-content').subscribe(res=>{
      this.service.hideSpinner();
      if(res['status']== 200){
       this.staticList = res['data'];

      }
    }, err=>{

      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Page Not Found');
   }
    })
  }

  // sidemenu() {
  //   $(".btn-toggle,.close_panel").click(function() {
  //     $("body").toggleClass("toggle-wrapper");
  // });
  // }

}
