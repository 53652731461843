import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-advertisement-details',
  templateUrl: './advertisement-details.component.html',
  styleUrls: ['./advertisement-details.component.css']
})
export class AdvertisementDetailsComponent implements OnInit {
  peerToPeerExchangeId: any;
  advertisementData: any;
  countryList: any = [];
  editAdvertisementForm: FormGroup;

  totalItems
  tradeList: any = []
  userId: any;
  // pagination
  itemsPerPageAdvPlace: any = 5;
  currentPageAdvPlace: any = 1;
  totalItemsAdvPlace: any;
  advPlaceInAdvertisementArray: any[] = [];
  itemsPerPageTradePlace: any = 5;
  currentPageTradePlace: any = 1;
  totalItemsTradePlace: any;
  tradePlaceInAdvertisementArray: any[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: MainService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
     
      if (res.id) {
        this.peerToPeerExchangeId = res.id
      }
    })
    this.editAdvertisementFormValidation()
    this.getCountryList()
    this.getAdvertisementDetails()
  }

  // edit advertisement form validation
  editAdvertisementFormValidation() {
    this.editAdvertisementForm = new FormGroup({
      'paymentType': new FormControl(''),
      'country': new FormControl(''),
      'fiatCoin': new FormControl(''),
      'margin': new FormControl(''),
      'termsOfTrade': new FormControl(''),
      'isIdentifiedPeople': new FormControl(''),
      'twpfaType': new FormControl('')
    })
  }

  // get country list
  getCountryList() {
    this.service.showSpinner()
    this.service.get('account/get-country-list').subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.countryList = res['data']
      }
    }, err => {
      this.service.hideSpinner()
    })
  }

  // get advertsement details
  getAdvertisementDetails() {
    this.service.showSpinner()
    this.service.get(`p2p-exchange/admin/search-and-filters-advertisement?page=0&pageSize=5&peerToPeerExchangeId=${this.peerToPeerExchangeId}`).subscribe((res) => {
     
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        this.advertisementData = res['data'].list[0]
        this.userId = this.advertisementData.userId
        this.editAdvertisementForm.patchValue({
          'paymentType': res['data'].list[0].paymentType ? res['data'].list[0].paymentType : '',
          'country': res['data'].list[0].country ? res['data'].list[0].country : '',
          'fiatCoin': res['data'].list[0].fiatCoin ? res['data'].list[0].fiatCoin : '',
          'margin': res['data'].list[0].margin ? res['data'].list[0].margin : '',
          'termsOfTrade': res['data'].list[0].termsOfTrade ? res['data'].list[0].termsOfTrade : '',
          'isIdentifiedPeople': res['data'].list[0].isIdentifiedPeople ? res['data'].list[0].isIdentifiedPeople : '',
          'twpfaType': res['data'].list[0].twpfaType == 'SMS' ? true : false
        })
        this.advPlaceInAdvertisement();
        this.tradePlaceInAdvertisement()
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
     
    })
  }

  // update advertisement details
  updateAdvertisement() {
    let data = {
      // "addTags": "string",
      "country": this.editAdvertisementForm.value.country ? this.editAdvertisementForm.value.country : this.advertisementData.country,
      // "cryptoCoin": "string",
      "fiatCoin": this.editAdvertisementForm.value.fiatCoin ? this.editAdvertisementForm.value.fiatCoin : this.advertisementData.fiatCoin,
      // "isIdentifiedPeople": true,
      "margin": this.editAdvertisementForm.value.margin ? this.editAdvertisementForm.value.margin : this.advertisementData.margin,
      // "maxValue": 0,
      // "minValue": 0,
      // "orderStatus": "ENABLE",
      // "orderType": "BUY",
      "paymentType": this.editAdvertisementForm.value.paymentType ? this.editAdvertisementForm.value.paymentType : this.advertisementData.paymentType,
      // "paymentWindow": 0,
      // "restrictAmount": 0,
      // "role": "DEVELOPER",
      // "roleId": 0,
      "termsOfTrade": this.editAdvertisementForm.value.termsOfTrade ? this.editAdvertisementForm.value.termsOfTrade : this.advertisementData.termsOfTrade,
      "twpfaType": "SMS",
      // "username": "string"
    }
   
    this.service.showSpinner()
    this.service.post(`p2p-exchange/edit-advertisment?peerToPeerExchangeId=${this.peerToPeerExchangeId}`, data).subscribe((res) => {
      
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    
    })
  }

  // delete advertisement
  deleteAdvertisement() {
    this.service.showSpinner()
    this.service.get(`p2p-exchange/admin/delete-addvertisement?peerToPeerExchangeId=${this.peerToPeerExchangeId}`).subscribe((res) => {
     
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        this.router.navigate(['/advertisement-management'])
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
     
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }

  // navigate back to advertisement management page
  cancel() {
    this.router.navigate(['/advertisement-management'])
  }

  // pagination(event) {

  // }



  advPlaceInAdvertisement() {
    let url = `p2p-exchange/admin/get-open-trade-list?page=${this.currentPageAdvPlace - 1}&pageSize=${this.itemsPerPageAdvPlace}&userId=${this.userId}`
    this.service.showSpinner()
    this.service.get(url).subscribe((res) => {
     
      this.service.hideSpinner()
      if (res['status'] == 200) {
        // this.service.toasterSucc(res['message'])
        this.advPlaceInAdvertisementArray = res['data']['RESULT_LIST'] ? res['data']['RESULT_LIST'] : []
        this.totalItemsAdvPlace = res['data']['TOTAL_COUNT'] ? res['data']['TOTAL_COUNT'] : 0
      } else {
        this.advPlaceInAdvertisementArray = []
        this.totalItemsAdvPlace = 0
        // this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
     
    })
  }
  paginationAdv(event) {
    this.currentPageAdvPlace = event;
    this.advPlaceInAdvertisement();
  }


  tradePlaceInAdvertisement() {
    // let url = `p2p-exchange/admin/get-open-trade-list?page=${this.currentPageTradePlace - 1}&pageSize=${this.itemsPerPageTradePlace}&userId=${this.userId}`
    // this.service.showSpinner()
    // this.service.get(url).subscribe((res) => {
     
    //   this.service.hideSpinner()
    //   if (res['status'] == 200) {
    //     // this.service.toasterSucc(res['message'])
    //     this.tradePlaceInAdvertisementArray = res['data']['RESULT_LIST'] ? res['data']['RESULT_LIST'] : []
    //     this.totalItemsTradePlace = res['data']['TOTAL_COUNT'] ? res['data']['TOTAL_COUNT'] : 0
    //   } else {
    //     this.tradePlaceInAdvertisementArray = []
    //     this.totalItemsTradePlace = 0
    //     // this.service.toasterErr(res['message'])
    //   }
    // }, err => {
    //   this.service.hideSpinner()
    //   this.service.toasterErr(err['message'])
     
    // })
  }
  paginationTrade(event) {
    this.currentPageTradePlace = event;
    this.tradePlaceInAdvertisement();
  }


  //export User
  exportAsXLSXAdvPlace() {
    let dataArr = [];
    this.advPlaceInAdvertisementArray.forEach((element, ind) => {
      dataArr.push({
        "ID": element.peerToPeerExchangeId ? element.peerToPeerExchangeId : '',
        "Date/Time": element.creationTime ? element.creationTime.slice(0, 10) : 'N/A',
        "User": element.userName ? element.userName : 'N/A',
        "Action": element.statusType ? element.statusType : 'N/A',
      })
    })
    this.service.exportAsExcelFile(dataArr, 'Advertisement Details List');
  }

  //export User
  exportAsXLSXTradePlace() {
    let dataArr = [];
    this.tradePlaceInAdvertisementArray.forEach((element, ind) => {
      dataArr.push({
        "ID": element.peerToPeerExchangeId ? element.peerToPeerExchangeId : '',
        "Date/Time": element.creationTime ? element.creationTime.slice(0, 10) : 'N/A',
        "User": element.userName ? element.userName : 'N/A',
        "Action": element.statusType ? element.statusType : 'N/A',
      })
    })
    this.service.exportAsExcelFile(dataArr, 'Trade Place in Adv List');
  }

}
