import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;

  constructor(public service: MainService, public route: Router) { }

  ngOnInit() {
    localStorage.removeItem('auth')
    this.token = window.location.href.split('=')[1];
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }


  // Reset Password Functionality
  resetPasswordFunc() {
    var apireq = {
      'password': this.resetPasswordForm.value.password,
      'token': this.token
    }
    console.log(apireq);
    
    this.service.showSpinner();
    this.service.post('account/reset-password', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc('Password set successfully.');
        this.route.navigate(['/login'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

}
