<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Advertisement Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new">
                            <div class="row">

                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select" style="margin-top: 20px;">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center"> </span>
                                            <input type="text" placeholder="Search by Ad Id" class="form-control"
                                                [(ngModel)]="peerToPeerExchangeId" style="height: 38px;">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select" style="margin-top: 20px;">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center"> </span>
                                            <input type="text" placeholder="Search by user name" class="form-control"
                                                [(ngModel)]="userName" style="height: 38px;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Start Date</span>
                                            <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}" name="fromdate"
                                                [(ngModel)]="calender.fromdate" (change)="fromdate()"
                                                class="form-control datepicker" placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">End Date</span>
                                            <input type="date" name="todate" min="{{fromDate | date:'yyyy-MM-dd'}}"
                                                (change)="todate()" [disabled]="!calender.fromdate"
                                                [(ngModel)]="calender.todate" class="form-control datepicker"
                                                placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 user-pl">
                                    <div class="user-frmbtn" style="display: flex;">
                                        <button type="submit" (click)="search()"
                                            class="btn  btn-theme mr-2">Search</button>
                                        <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>
                                    </div>
                                </div>
                            </div>


                            <div class="row mt-3">
                                <div class="col-md-3" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Status</span>
                                            <select class="form-control" [(ngModel)]="orderStatus">
                                                <option value="" placeholder="Select">Select</option>

                                                <option value="ENABLED">Enable</option>
                                                <option value="DISABLED">Disable</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Country</span>
                                            <select class="form-control" [(ngModel)]="country">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of countryList" [value]="item?.country">
                                                    {{item?.country}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Payment Method</span>
                                            <select class="form-control" [(ngModel)]="paymentType">
                                                <option value="">Select</option>
                                                <option value="NationalBankTransfer">National Bank Transfer</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="paypal">PAYPAL</option>
                                                <option value="paytm">PAYTM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Type</span>
                                            <select class="form-control" [(ngModel)]="orderType">
                                                <option value="">Select</option>
                                                <option value="SELL">Sell</option>
                                                <option value="BUY">Buy</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-2 user-pl">
                                    <div class="user-frmbtn">
                                        <button type="submit" (click)="exportAsXLSX()"
                                            class="btn  btn-theme mr-2">EXPORT AS EXCEL</button>

                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No</th>
                                        <th id="">Ad. ID</th>
                                        <th id="">User Name</th>
                                        <th id="">Created Date & Time</th>
                                        <th id="">Status</th>
                                        <th id="">Type</th>
                                        <th id="">Payment Method</th>
                                        <th id="">Location</th>
                                        <!-- <th id="">Action</th> -->
                                        <!-- <th id="">Price Equation</th> -->
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr
                                        *ngFor="let data of advertisementData | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td>{{data?.peerToPeerExchangeId}}</td>
                                        <td class="trade-table-data" (click)="navigate(data.peerToPeerExchangeId)"><u>{{data?.userName}}</u></td>
                                        <td>{{data?.creationTime | date:'medium'}}</td>
                                        <td>{{data?.orderStatus | titlecase}}</td>
                                        <td>{{data?.orderType}}</td>
                                        <td>{{data?.paymentType}}</td>
                                        <td>{{data?.country}}</td>
                                        <!-- <td *ngIf="data?.orderStatus=='ENABLED'" data-toggle="modal"
                                        (click)="openModal(data?.peerToPeerExchangeId)"><em
                                            class="fa fa-ban text-success"></em></td>

                                    <td *ngIf="data?.isBlockedStatus == true" data-toggle="modal"
                                        ><em
                                            class="fa fa-ban text-danger"></em></td>
                                            <td *ngIf="data?.orderStatus == 'DISABLED'" data-toggle="modal"
                                            ><em
                                                class="fa fa-times text-danger border border-warning"></em></td> -->
                                        <!-- <td class="action_td_btn3" style="cursor: pointer">
                                            <a><em class="fa fa-eye text-view" (click)="navigate(data.peerToPeerExchangeId)"></em></a>
                                            <a><em class="fa fa-ban text-success" *ngIf="data?.orderStatus=='ENABLED' && data?.isBlockedStatus== false" data-toggle="modal"
                                                (click)="openModal(data?.peerToPeerExchangeId)"></em></a>
                                            <a><em class="fa fa-ban text-danger" *ngIf="data?.isBlockedStatus == true" data-toggle="modal"></em></a>
                                            <a><em class="fa fa-times text-danger border border-warning" *ngIf="data?.orderStatus == 'DISABLED'" data-toggle="modal"></em></a>
                                        </td> -->

                                        <!-- <td class="action_td_btn3" style="cursor: pointer">
                                            <a><em class="fa fa-eye" (click)="navigate(data.peerToPeerExchangeId)"></em></a>
                                            <a><em class="fa fa-ban" *ngIf="data?.orderStatus=='ENABLED' && data?.isBlockedStatus== false" data-toggle="modal" (click)="openModal(data?.peerToPeerExchangeId)"></em></a>
                                            <a *ngIf="data?.isBlockedStatus == true" data-toggle="modal"><em class="fa fa-ban text-danger"></em></a>
                                            <a *ngIf="data?.orderStatus == 'DISABLED'" data-toggle="modal">
                                                <em class="fa fa-times text-danger border border-warning"></em>
                                            </a>
                                        </td> -->


                                        <!-- <td>{{data?.price}}</td> -->
                                    </tr>
                                    <tr *ngIf="advertisementData?.length==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="totalItems > 5 ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</main>

<div class="modal fade global-modal reset-modal" id="blockadv">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 25px; ">Block Advertisement </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Are you sure you want to block this advertisement?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="performAction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
