import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $:any
@Component({
  selector: 'app-advertisement-management',
  templateUrl: './advertisement-management.component.html',
  styleUrls: ['./advertisement-management.component.css']
})
export class AdvertisementManagementComponent implements OnInit {

  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', fromdate: '' }
  minAge: Date;

  advertisementData: any = []

  userId2: any = ''
  userName: any = ''
  paymentType: any = ''
  orderType: any = ''
  country: any = ''
  orderStatus: any = ''

  itemsPerPage: any = 15;
  currentPage: any = 1;
  totalItems: any;

  countryList: any = []
  advId: any;
  peerToPeerExchangeId:any
  constructor(public router: Router, public service: MainService) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.getAdvertisement()
    this.getCountryList()
  }

  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime() + (1000 * 60 * 60 * 24)
  }

  // get country list
  getCountryList() {
    this.service.get('account/get-country-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.countryList = res['data']

      }
    })
  }

  // get advertisement list
  getAdvertisement() {
    this.service.showSpinner()
    let url = `p2p-exchange/admin/search-and-filters-advertisement?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${(this.peerToPeerExchangeId ? ('&peerToPeerExchangeId=' + this.peerToPeerExchangeId) : '') + (this.userName ? ('&userName=' + this.userName) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '') + (this.orderStatus ? ('&orderStatus=' + this.orderStatus) : '') + (this.country ? ('&country=' + this.country) : '') +
      (this.paymentType ? ('&paymentType=' + this.paymentType) : '') + (this.orderType ? ('&orderType=' + this.orderType) : '')}`
    this.service.get(url).subscribe((res) => {
    
      this.service.hideSpinner()
      if (res['status'] == 200) {
        // this.service.toasterSucc(res['message'])
        this.advertisementData = res['data'].list
        this.totalItems = res['data'].totalCount
       

      } else {
        this.service.toasterErr(res['message'])
        this.advertisementData = [];
        this.totalItems = 0
      }
    }, err => {
    
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
      this.advertisementData = [];
      this.totalItems = 0
    })
  }

  // search advertisement
  search() {
    if (this.peerToPeerExchangeId || this.userName || this.fromDate || this.twoDate || this.orderStatus || this.country || this.paymentType || this.orderType) {
     
      this.getAdvertisement()
    } else {
      console.log("empty clicked")
    }
  }

  // reset search fields
  reset() {
    if (this.peerToPeerExchangeId  || this.userName || this.fromDate || this.twoDate || this.orderStatus || this.country || this.paymentType || this.orderType) {
     
      this.peerToPeerExchangeId = ''
      this.userName = ''
      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.orderStatus = ''
      this.country = ''
      this.paymentType = ''
      this.orderType = ''
      this.getAdvertisement()
    } else {
      
    }
  }

  // pagination
  pagination(page) {
   
    this.currentPage = page
    this.getAdvertisement()
  }

  // navigate to advertisement details page
  navigate(peerToPeerExchangeId) {
    this.router.navigate(['/advertisement-details', peerToPeerExchangeId])
  }

   //export User
   exportAsXLSX() {
    let dataArr = [];
    this.advertisementData.forEach((element, ind) => {
      dataArr.push({

        "Ad ID": element.peerToPeerExchangeId ? element.peerToPeerExchangeId : '',
        "User Name": element.userName ? element.userName  : 'N/A',
        "Created Date & Time": element.creationTime ? element.creationTime.slice(0, 10) : 'N/A',
        "Status": element.orderStatus ,
        "Type": element.orderType ? element.orderType : 'N/A',
        "Payment Method": element.paymentType ? element.paymentType : 'N/A',
        "Location": element.country ? element.country : 'N/A',



      })
    })
    this.service.exportAsExcelFile(dataArr, 'Advertisement list');
  }

  openModal(id){
    $('#blockadv').modal('show')
    this.advId=id
  }

  performAction() {
    var url = 'p2p-exchange/admin/add-blocked-by-admin?p2pId='+this.advId
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
          $('#blockadv').modal('hide');
          this.service.toasterSucc('Advertisement blocked successfully.');

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }
}
