 <main class="middle-content">
  <div class="page_title_block">

      <h1 class="page_title" style="color:black">ADVERTISEMENT DETAILS</h1>
  </div>
  <div class="content-section" style="margin-bottom: 50px;">
      <div class="outer-box">
          <div class="custom_tabs common-tabs">
              <div class="tab-content">
                  <div class="row">




                      <div class="col-lg-6">
                          <div>
                              <div class="transaction" style="margin-top:-2px">
                                  <h3>Ad Details</h3>
                                  <div class="text-left" style="float: right;">
                                      <button type="submit" class="btn btn-theme"
                                          (click)="exportAsXLSXAdvPlace()">Export in
                                          Excel</button>
                                  </div>
                              </div>
                              <div class="table-responsive" style="text-align: center;">
                                  <table class="table table-bordered" aria-describedby="trade table">
                                      <thead>
                                          <tr class="no_wrap_th">
                                              <th id="">ID</th>
                                              <th id="">Date/Time</th>
                                              <th id="">User</th>
                                              <th>Margin</th>
                                              <th>Order Type</th>
                                              <th id="">Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>

                                          <tr
                                              *ngFor="let item of advPlaceInAdvertisementArray | paginate:{itemsPerPage: itemsPerPageAdvPlace, currentPage: currentPageAdvPlace,totalItems: totalItemsAdvPlace, id:'adv'}">
                                              <td>{{item?.peerToPeerExchangeId}}</td>
                                              <td>{{item?.creationTime | date:'medium'}}</td>
                                              <td>{{item?.userName}}</td>
                                              <td>{{item?.margin}}</td>
                                              <td>{{item?.orderType}}</td>
                                              <td>{{item?.statusType | titlecase}}</td>
                                          </tr>
                                          <tr *ngIf="totalItemsAdvPlace == 0">
                                              <td colspan="11" vertical-align="middle">
                                                  <div class="no-record text-center">
                                                      <div class="no-recordin">
                                                          <h5>No record found</h5>
                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <div class="custom-pagination mt20 text-center" style="float: right;"
                                  *ngIf="totalItemsAdvPlace > 5 ">
                                  <pagination-controls (pageChange)="paginationAdv($event)" id="adv">
                                  </pagination-controls>
                              </div>
                          </div>


                      </div>
                      <div class="col-lg-6" >
                        <div class="card" >
                        <form [formGroup]="editAdvertisementForm" style="margin-left: 15%;padding-top: 8px;">
                            <div class="form-group row">
                                <div class="col-md-4 edit-text ">ID</div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">{{advertisementData?.peerToPeerExchangeId}}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4 edit-text ">Username </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">{{advertisementData?.userName}}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4 edit-text">Created On </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">{{advertisementData?.creationTime | date:'medium'}}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4 edit-text ">Payment Method </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">

                                            <p>{{advertisementData?.paymentType}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-4 edit-text">Fiat Coin</div>


                                <div class="col-md-1">:</div>
                                <div class="col-md-7">{{advertisementData?.fiatCoin}}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4 edit-text">Price</div>


                                <div class="col-md-1">:</div>
                                <div class="col-md-7">{{advertisementData?.price}}</div>
                            </div>
                            <!-- <div class="form-group row">
                                <div class="col-md-4 edit-text">Margin </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7" >{{advertisementData?.margin || '---'}}

                                </div>
                            </div> -->
                            <div class="form-group row">
                                <div class="col-md-4 edit-text">Ad Limit</div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">Min- {{advertisementData?.minValue}} <br> Max-
                                    {{advertisementData?.maxValue}}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4 edit-text">Active Hours </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">24hrs</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4 edit-text">Terms of Trade</div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7" >{{advertisementData?.termsOfTrade || '---'}}

                                </div>
                            </div>

                        </form>
                        <div class="mt-10 " style="text-align: center;margin-bottom: 20px; margin-left: -9%;">

                          <button class="btn  btn-theme" type="button" (click)="cancel()">Cancel</button>


                      </div>

                    </div>
                    </div>

                  </div>


              </div>
          </div>
      </div>

  </div>
</main>
