import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl } from '@angular/forms';

declare var $: any

@Component({
  selector: 'app-referral-setting',
  templateUrl: './referral-setting.component.html',
  styleUrls: ['./referral-setting.component.css']
})
export class ReferralSettingComponent implements OnInit {

  userDetail: any = {};
  referralForm: FormGroup;
  referralData: any;
  refToggle: boolean = false;
  referralSettingId: any;

  constructor(
    public service: MainService, public router: Router
  ) { }

  ngOnInit() {
    this.referralFormValidation();
    this.getReferralAmount();
    // this.referralEnableDisable()
    // this.getReferralSetting();
  }

  referralFormValidation() {
    this.referralForm = new FormGroup({
      'amount': new FormControl('')
    })
  }

  getReferralAmount() {
    let url = 'account/admin/user-management/get-referral-amount'
    this.service.get(url).subscribe((res: any) => {
      console.log(res)
      if(res.status == 200){
        this.referralData = res['data']
        this.refToggle = res['data']['isEnable']
        this.referralSettingId = res['data']['referralSettingId']
        this.referralForm.patchValue({
          'amount': res['data']['amount']
        })
      }
    })
  }
  //   message: "not data found"
  // status: 205
  // timestamp: "2021-03-13T12:27:17.664+0000"

  // account/admin/user-management/enable-referral
  referralEnableDisable() {
    let apiReqData = {
      "isEnable": this.refToggle =! this.refToggle,
      "referralSettingId": this.referralSettingId
    }
    apiReqData = this.service.removeEmptyKey(apiReqData)
    let url = 'account/admin/user-management/enable-referral'
    this.service.post(url, apiReqData).subscribe((res: any) => {
      console.log(res)
    })
  }


  getReferralSetting() {
    let apiReqData = {
      "amount": this.referralForm.value.amount,
      "referralSettingId": this.referralSettingId
    }
    apiReqData = this.service.removeEmptyKey(apiReqData)
    var url = 'account/admin/user-management/set-referral-amount';
    this.service.showSpinner();
    this.service.post(url, apiReqData).subscribe(res => {
      console.log(res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        //  this.userDetail = res['data']
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}
