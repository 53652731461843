import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl } from '@angular/forms';
// import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-admin',
  templateUrl: './view-admin.component.html',
  styleUrls: ['./view-admin.component.css']
})
export class ViewAdminComponent implements OnInit {
  userId: any;
  adminDetails: any;
  editAdminForm: FormGroup;
  adminPrivileges: any;

  constructor(public router: Router, public param: ActivatedRoute, public service: MainService) {
    this.param.params.subscribe((res) => {
      this.userId = res.id;
      console.log("userId", res)
    })
  }

  ngOnInit() {
    this.editAdminForm = new FormGroup({
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      userChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
    this.getProfile();
  }

  back() {
    this.router.navigate(['/admin-management'])
  }

  // get admin details
  getProfile() {
    let dataa = {
      "primaryIdCommonPerRequest": this.userId
    }
    this.service.showSpinner();
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      console.log("get admin details==>", res)
      this.adminDetails = res['data']['staffDetails']
      // console.log("hgfsjdhcfgf",this.adminDetails)
      this.adminPrivileges = res['data']['staffPrivileges']
      this.adminPrivileges.forEach(element => {
        if (element == 'DASHBOARD') {
          this.editAdminForm.patchValue({
            dashboardChecked: true
          })
        } else if (element == 'HOT_COLD_LIMIT_MANAGEMENT') {
          this.editAdminForm.patchValue({
            hotWalletChecked: true
          })
        }
        else if (element == 'KYC_MANAGEMENT') {
          this.editAdminForm.patchValue({
            kycChecked: true
          })
        }
        else if (element == 'WALLET_MANAGEMENT') {
          this.editAdminForm.patchValue({
            walletChecked: true
          })
        }
        else if (element == 'STATIC_CONTENT') {
          this.editAdminForm.patchValue({
            staticChecked: true
          })
        }
        else if (element == 'LOGS_MANAGEMENT') {
          this.editAdminForm.patchValue({
            logsChecked: true
          })
        }
        else if (element == 'TICKET_MANAGEMENT') {
          this.editAdminForm.patchValue({
            ticketChecked: true
          })
        }
        else if (element == 'TRADE_MANAGEMENT') {
          this.editAdminForm.patchValue({
            tradeChecked: true
          })
        }
        else if (element == 'DISPUTE_MANAGEMENT') {
          this.editAdminForm.patchValue({
            disputeChecked: true
          })
        }
        else if (element == 'STAFF_MANAGEMENT') {
          this.editAdminForm.patchValue({
            staffChecked: true
          })
        }
        else if (element == 'USER_MANAGEMENT') {
          this.editAdminForm.patchValue({
            userChecked: true
          })
        }
        else if (element == 'BANK_MANAGEMENT') {
          this.editAdminForm.patchValue({
            bankChecked: true
          })
        }
        else if (element == 'FEE_MANAGEMENT') {
          this.editAdminForm.patchValue({
            feeChecked: true
          })
        }
      });
      setTimeout(() => {

        this.service.hideSpinner();
      }, 2000)
    })
  }

}