import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ServiceService } from '../service.service';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup;

  constructor(
    private router: Router,
    public service: MainService
  ) { }

  ngOnInit() {
    this.forgotPassword = new FormGroup({

      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)),
    })
  }


  onForgot() {
    var email = this.forgotPassword.value.email;
    // var url = "account/forget-password?email=" + email + '&webUrl=' + this.service.websiteURL + 'reset-password';
    var url = "account/forget-password?email=" + email + '&webUrl=' + this.service.webUrl + 'reset-password';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Email address is not registered.');
      }
    });
  }

  onResend() {
    if (!this.forgotPassword.value.email) {
      return this.service.toasterErr('Enter email address.');
    }
    else if (this.forgotPassword.invalid) {
      this.service.toasterErr('Enter valid email address.');
    }
    else {
      this.onForgot();
    }
  }

}
