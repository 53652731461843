import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.css']
})
export class AddNotificationComponent implements OnInit {
  addNotificationForm: FormGroup;
  // multi select deopdown
  dropdownUserList = [];
  selectedUsers = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'userId',
    // textField: 'userName',
    textField: 'email',
    selectAllText: 'Select All User',
    unSelectAllText: 'UnSelect All User',
    itemsShowLimit: 1,
    allowSearchFilter: false
  };

  constructor(public service: MainService, public router: Router) { }

  ngOnInit() {
    this.addNotificationFormValidation();
    this.getUser();
  }

  addNotificationFormValidation() {
    this.addNotificationForm = new FormGroup({
      'subject': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required)
    })
  }

  getUser() {
    let url = 'account/admin/user-management/filter-user-details'
    this.service.get(url).subscribe((res: any) => {
     
      if (res.status == 200) {
        this.dropdownUserList = res['data']['list']
      }
    })
  }

  sendNotification() {
    let selectedUsersSend = []
    if (!this.selectedUsers.length) {
      return this.service.toasterErr('Please select user.')
    } else {
      this.selectedUsers.forEach((element) => {
        selectedUsersSend.push(element.email)
      })
      let apiReqData = {
        "email": selectedUsersSend,
        "description": this.addNotificationForm.value.description,
        "modeOfBroadCast": "EMAIL",
        "subject": this.addNotificationForm.value.subject
      }
     
      apiReqData = this.service.removeEmptyKey(apiReqData)
      var url = 'notification/send-push-notification';
      this.service.showSpinner();
      this.service.post(url, apiReqData).subscribe(res => {
       
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.router.navigate(['notification-management'])
        } else {
          this.service.toasterErr(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.onLogout();
          this.service.toasterErr('Unauthorized access.');
        } else {
          this.service.toasterErr('Something went wrong.');
        }
      })
    }

  }

}
