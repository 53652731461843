import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  id: any;
  about: any;
  dataa: any = [];
  constructor(public service: MainService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(x => {

      this.id = x['id'];
    })
    this.getListCode();
  }

  // Get List Code by admin
  getListCode() {
    this.service.showSpinner();
    this.service.get('static/get-static-content?pageKey=About%20Us').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.dataa = res['data'];
        // this.about = data.filter(x=>(x.staticContentId == this.id))
       
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        // this.service.toasterErr('Unauthorized access.');
        this.service.onLogout();
      } else {
        // this.service.toasterErr('Something went wrong.');
      }
    })
  }

  // Save Abou Us Functionality
  saveAboutUS() {
    var apiReq = {
      "contentId": this.dataa.staticContentId,
      "pageData": this.dataa.pageData
    }
    this.service.showSpinner();
    this.service.post('static/admin/static-content/update-static-content', apiReq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getListCode();
        this.service.toasterSucc('About us updated successfully.')
      } else {
        this.service.toasterErr('About us updated successfully.')
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

}
