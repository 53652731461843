import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {
  addForm: FormGroup;
  ipAddress: any;
  newArr: any = [];

  // constructor(private http: HttpClient){
  //   this.http.get<{ip:string}>('https://jsonip.com')
  //   .subscribe( data => {
  //   
  //     this.ipAddress = data
  //   })
  // }

  constructor(public service: MainService, public router: Router, private http: HttpClient) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        
        this.ipAddress = data.ip
       
      })
  }

  ngOnInit() {
    this.addForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'lastName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'role': new FormControl('', Validators.required),
      'gender': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', [Validators.required, Validators.pattern(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/)])
    })
  }

  // ---------------- add sub admin ---------------- //
  addStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.addForm.value.email,
      "firstName": this.addForm.value.firstName,
      "gender": this.addForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.addForm.value.lastName,
      "location": "string",
      "phoneNo": this.addForm.value.phoneNumber,
      "previlage": this.newArr,
      // "roleStatus": "ADMIN",
      "roleStatus": this.addForm.value.role,
      "state": "string",
      "webUrl": this.service.webUrl + 'reset-password',
      // "userIdToUpdate": 12
    }
  
    this.service.postApi('account/admin/user-management/create-sub-admin', data).subscribe((res) => {
     
      if (res['status'] == 200) {
        this.service.toasterSucc('An email has been sent to registered email address, kindly check for password recovery.')
        this.router.navigate(['/admin-management'])
      } else {
        this.service.toasterErr(res.message)
      }
    }, (error: any) => {
     
      this.service.toasterErr('Something went wrong.')
    })
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
   
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
    
      this.newArr.splice(index, 1)
     
    }
  }

  cancel() {
    this.router.navigate(['/admin-management'])
  }

}
