<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add Staff</h1>
    </div>
    <div class="content-section">
        <div class="outer-box" [formGroup]="addForm">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="form-group row w-100">
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">First Name </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input class="form-control" type="text" maxlength="60" formControlName="firstName"
                                        (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('firstName').invalid && (addForm.get('firstName').dirty  || addForm.get('firstName').touched)">
                                        <span *ngIf="addForm.get('firstName').hasError('required')">*First name is
                                            required.</span>
                                        <span *ngIf="addForm.get('firstName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">Last Name </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input class="form-control" type="text" maxlength="60" formControlName="lastName"
                                        (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('lastName').invalid && (addForm.get('lastName').dirty  || addForm.get('lastName').touched)">
                                        <span *ngIf="addForm.get('lastName').hasError('required')">*Last name is
                                            required.</span>
                                        <span *ngIf="addForm.get('lastName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group row w-100">
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">Email </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input class="form-control" type="text" formControlName="email" maxlength="60"
                                        (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('email').invalid && (addForm.get('email').dirty  || addForm.get('email').touched)">
                                        <span *ngIf="addForm.get('email').hasError('required')">*Email is
                                            required.</span>
                                        <span *ngIf="addForm.get('email').hasError('pattern')">*Please enter
                                            valid email.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">Mobile Number </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" formControlName="phoneNumber" maxlength="18"
                                        (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('phoneNumber').invalid && (addForm.get('phoneNumber').dirty  || addForm.get('phoneNumber').touched)">
                                        <span *ngIf="addForm.get('phoneNumber').hasError('required')">*Mobile number is
                                            required.</span>
                                        <span *ngIf="addForm.get('phoneNumber').hasError('pattern')">*Please enter
                                            valid number.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group row w-100">
                        <div class="col-md-6">
                            <label class="col-md-4">Gender </label>
                            <span class="col-md-2 text-center">:</span>

                            <div class="col-md-6">
                                <select class="form-control" formControlName="gender">
                                    <option value="">Select Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                    <option value="OTHER">Other</option>
                                </select>
                                <div class="text-danger"
                                    *ngIf="addForm.get('gender').invalid && (addForm.get('gender').dirty || addForm.get('gender').touched)">
                                    <span *ngIf="addForm.get('gender').hasError('required')">*Gender is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-4">Role </label>
                            <span class="col-md-2 text-center">:</span>
                            <div class="col-md-6">
                                <select class="form-control" formControlName="role">
                                    <option value="">Select Role</option>
                                    <option value="SUBADMIN">Staff</option>
                                </select>
                                <div class="text-danger"
                                    *ngIf="addForm.get('role').invalid && (addForm.get('role').dirty || addForm.get('role').touched)">
                                    <span *ngIf="addForm.get('gender').hasError('required')">*Role is required.</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <table aria-describedby="permission table">
                        <tr>
                            <th scope="col">Permission</th>
                            <th scope="col">Operations</th>
                        </tr>
                        <tr>
                            <td>Dashboard</td>
                            <td>
                                <input type="checkbox" (change)="checkboxClick('DASHBOARD',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Role Management</td>
                            <td>
                                <input type="checkbox"
                                    (change)="checkboxClick('STAFF_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>User Management</td>
                            <td>
                                <input type="checkbox"
                                    (change)="checkboxClick('USER_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Hot Wallet Management</td>
                            <td>
                                <input type="checkbox"
                                    (change)="checkboxClick('HOT_COLD_LIMIT_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>KYC Management</td>
                            <td>
                                <input type="checkbox" (change)="checkboxClick('KYC_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Wallet Management</td>
                            <td>
                                <input type="checkbox"
                                    (change)="checkboxClick('WALLET_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Static Content Management</td>
                            <td>
                                <input type="checkbox" (change)="checkboxClick('STATIC_CONTENT',$event.target.checked)">
                            </td>
                        </tr>
                      

                        <tr>
                            <td>Trade Management</td>
                            <td>
                                <input type="checkbox"
                                    (change)="checkboxClick('TRADE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Dispute Management</td>
                            <td>
                                <input type="checkbox"
                                    (change)="checkboxClick('DISPUTE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>

                        <tr>
                            <td>Fee Management</td>
                            <td>
                                <input type="checkbox" (change)="checkboxClick('FEE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                    </table>
                    &nbsp;
                    <div style="text-align: center">
                        <button class="btn  btn-theme" type="button" [disabled]="addForm.invalid"
                            (click)="addStaff()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>