<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Notification Management</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">

                        <form [formGroup]="addNotificationForm">
                            <div class="form-group row">
                                <label class="col-md-3 edit-text">Subject </label>
                                <div class="col-md-1"> :</div>
                                <div class="col-md-8 ">
                                    <input type="text" class="form-control" placeholder="Subject"
                                        formControlName="subject" maxlength="60">
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-3 edit-text">Select User </label>
                                <div class="col-md-1"> :</div>
                                <div class="col-md-8">
                                    <ng-multiselect-dropdown [placeholder]="'Select User'" [settings]="dropdownSettings"
                                        [data]="dropdownUserList" [(ngModel)]="selectedUsers"
                                        [ngModelOptions]="{standalone: true}">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-3 edit-text">Description : </label>
                                <!-- <div class="col-md-1"> :</div>
                                <div class="col-md-8">
                                    <textarea class="form-control" name="" id="" cols="30" rows="10"
                                        placeholder="Description" formControlName="description"></textarea>
                                </div> -->
                            </div>
                            <div class="form-group row align-items-baseline">

                              <div class="col-md-12">
                                  <textarea class="form-control" name="" id="" cols="30" rows="10"
                                      placeholder="Description" formControlName="description"></textarea>
                              </div>
                          </div>
                        </form>

                        <div class="text-center mt40">
                            <button routerLinkActive="router-link-active"
                                class="btn btn-large  max-WT-200 font-100 btn-green mr-2"
                                [disabled]="addNotificationForm.invalid" (click)="sendNotification()">Submit</button>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green ml5 "
                                [routerLink]="['/notification-management']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
