import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

declare var $: any
@Component({
  selector: 'app-referral-details',
  templateUrl: './referral-details.component.html',
  styleUrls: ['./referral-details.component.css']
})
export class ReferralDetailsComponent implements OnInit {

  userDetail: any = {};
  referralId: any;
  constructor(private activatedRoute: ActivatedRoute,
    public service: MainService, public router: Router
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      console.log(res);
      if (res.id) {
        // this.toEmail = 'ds123@mailinator.com'
        this.referralId = res.id
        console.log("to user email->", this.referralId)
      }
    })
    this.getReferralDetails();
  }

  getReferralDetails() {
    var url = 'account/admin/user-management/filter-referral-details?referralId=' + this.referralId;
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']['list'][0]
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}
