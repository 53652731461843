<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Notification Management</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Brodcast Id </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{notificationData?.brodcastId}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Subject</label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{notificationData?.subject}}</label>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Date & Time </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{notificationData?.createdat | date:'medium'}}</label>
                            </div>
                        </div>
                    

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Description </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label style="word-wrap: break-word; display: block">
                                    {{notificationData?.description}}</label>
                            </div>
                        </div>
                        <div class="text-left mt40 text-center">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green ml5 "
                                [routerLink]="['/notification-management']">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>