<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">My Profile</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">

                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'" class="profile-pic" alt="">
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Name </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{userDetail?.firstName}} {{userDetail?.lastName}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Email </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label><a href="javascrip:void(0)">{{userDetail?.email}}</a></label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Mobile Number</label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label><a href="javascrip:void(0)">{{userDetail?.phoneNo}}</a></label>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">City </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{userDetail?.city}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Country </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{userDetail?.country}}</label>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Address </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label style="word-wrap: break-word;"> {{userDetail?.address}}</label>

                            </div>
                        </div>
                        <div class="text-left mt40">
                            <a [routerLink]="['/edit-profile']" routerLinkActive="router-link-active"
                                class="btn btn-large  max-WT-200 font-100 btn-green mr-2">Edit Profile</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green ml5 "
                                [routerLink]="['/setting']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
<!-- Wrapper End -->
<!--Modal Start-->
<div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body  text-center">
                <div class="row align-items-center modal_flax_height">
                    <div class="col">
                        <div class="box-title mb40 d-inline-block">
                            <h2>Are you sure?</h2>
                            <p>You won’t be able to revert this!</p>
                        </div>
                        <div class="max-WT-300 d-inline-block">
                            <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                            <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                data-dismiss="modal">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- signout_modal Start -->

<!-- Signout Modal -->
<!-- Change Password_modal Start -->
<div class="modal fade global-modal reset-modal" id="change_passwprd_modal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header ">
                    <h4 class="modal-title text-center">Change Password</h4>

                </div>
                <div class="modal-body">
                    <div class="row align-items-center modal_flax_height">
                        <div class="col">
                            <div class="form-group">
                                <label class="control-labe">Old Password</label>
                                <input class="form-control" placeholder="" required="" type="text">
                            </div>
                            <div class="form-group">
                                <label class="control-labe">New Password</label>
                                <input class="form-control" placeholder="" required="" type="text">
                            </div>
                            <div class="form-group">
                                <label class="control-labe">Confirm Password</label>
                                <input class="form-control" placeholder="" required="" type="text">
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="submit"
                                        class="btn btn-gray btn-large radius0 btn-block">SUBMIT</button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                        data-dismiss="modal">CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>