import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.css']
})
export class NotificationManagementComponent implements OnInit {
  notificationList = []
  itemsPerPage: any = 10;
  currentPage: any = 1;
  totalItems: any=10;

  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
    this.getNotificationList();
  }

  // Get notification list  https://p2p-java.mobiloitte.com/notification/get-notification-data
  getNotificationList() {
    var url = `notification/get-notification-data?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 1619) {
        this.service.hideSpinner();
        console.log('This is Admin list', res)
        this.notificationList = res.data
        // this.totalItems = res.data ? res.data.count : 0;
      } else {
        this.service.hideSpinner();
        this.notificationList = [];
        this.totalItems = 0;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      } else {
        this.service.toasterErr('Something went wrong.');
      }
    })
  }

  pagination(event) {
    this.currentPage = event
    this.getNotificationList()
  }

  // add notification
  addNotification() {
    this.router.navigate(['/add-notification'])
  }

  // view notification
  viewNotification(userId) {
    this.router.navigate(['view-notification/' + userId])
  }

}
