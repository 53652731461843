
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Staff Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head">

                            <form [formGroup]="userForm">
                                <div class="row">
                                    <div class="col-md-12 col-lg-6">
                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center"
                                                            style="margin-left: 10px!important">From Date </span>
                                                        <my-date-picker style="margin-left: 6px; width: 200px;"
                                                            name="startdate" formControlName='startdate'
                                                            [options]="myDatePickerOptions"
                                                            (dateChanged)="onChange($event)" placeholder="Start date">
                                                        </my-date-picker>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-6">

                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">To Date</span>
                                                        <my-date-picker name="enddate" style="width:200px"
                                                            [disabled]='beDisable' formControlName='enddate'
                                                            [options]="toDate" placeholder="End date"></my-date-picker>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- <div class="col-md-2 user-pl">

                                                <div class="user-frmbtn">
                                                    <button type="submit" [disabled]="userForm.invalid"
                                                        (click)="search()" class="btn  btn-theme">Search</button>
                                                </div>

                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6">
                                        <div class="user-form2">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <div class="serch-boxuser">
                                                        <div class="input-group filter_search_group">
                                                            <input type="text" class="form-control"
                                                                placeholder="Search by email/username"
                                                                formControlName="searchText">
                                                            <div class="input-group-append">
                                                                <button class="btn btn_search_group" type="button"><img
                                                                        src="assets/img/icon-search.png"
                                                                        alt="Search"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 user-lst">
                                                    <div class="two-btnline">

                                                        <div class="head_flt_select">
                                                            <div class="head_flt_select input-label">
                                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                                    (click)="search()">Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="text-left">
                                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                                (click)="reset()">Reset
                                                            </button>
                                                        </div>


                                                    </div>

                                                </div>

                                                <div class="col-md-12">

                                                    <div class="tras-threebtn" style="float: right;">
                                                        <div class="comman-btn">
                                                            <button type="submit" class="btn  btn-theme"
                                                                (click)="exportAsXLSX()">EXPORT
                                                                AS EXCEL
                                                            </button>
                                                        </div>
                                                        <div class="comman-btn">
                                                            <button type="submit" class="btn  btn-theme"
                                                                (click)="addAdmin()">Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.NO</th>
                                        <th id="">User Name</th>
                                        <th id="">Email</th>
                                        <th id="">Role</th>
                                        <th id="">Mobile Number</th>
                                        <th id="">Status</th>
                                        <th id="">Last Logged In</th>
                                        <th id="">Created Date & Time</th>
                                        <th class="action_td_btn3" id="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of userList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td>{{data?.firstName}} {{data?.lastName}}</td>
                                        <td>{{data?.email || '---'}}</td>
                                        <td>Staff</td>
                                        <td>{{data?.phoneNo || '---'}}</td>
                                        <td>{{(data?.userStatus | titlecase) || '---'}}</td>
                                        <td>{{(data?.lastLoginTime | date:'medium') || '---'}}</td>
                                        <td>{{(data?.createdTime | date:'medium') || '---'}}</td>
                                        <td class="action_td_btn3" style="cursor: pointer">
                                            <a><em class="fa fa-eye" (click)="userdetail(data?.userId)"></em></a>
                                            <a data-toggle="modal" style="cursor: pointer"
                                                (click)="editAdmin(data?.userId)"><em class="fa fa-edit"
                                                    title="Edit"></em></a>
                                            <a><em class="fa fa-trash"
                                                    (click)="openModal('DELETE', data?.userId)"></em></a>
                                            <a data-toggle="modal" (click)="openModal('BLOCK', data?.userId)"
                                                *ngIf="data?.userStatus == 'ACTIVE'"><em
                                                    class="fa fa-ban text-success"></em></a>
                                            <a data-toggle="modal" (click)="openModal('ACTIVE', data?.userId)"
                                                *ngIf="data?.userStatus == 'BLOCK'"><em
                                                    class="fa fa-ban text-danger"></em></a>
                                        </td>

                                    </tr>
                                    <tr *ngIf="userList == ''">
                                        <td colspan="8"> No data found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-right" *ngIf="userList.length > 0 ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; font-size: 25px; ">Remove Staff </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Are you sure you want to remove this staff?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="deleteFunction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; font-size: 25px;">Block Staff </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Are you sure you want to block this staff?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="performActionActive()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->

<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; font-size: 25px;">Active Staff </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Are you sure you want to active this staff?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="performAction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
