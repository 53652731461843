import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.css']
})
export class FinancesComponent implements OnInit {
  minAge: Date;
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', fromdate: '' }
  searchByEmail: any;

  itemsPerPage = 15
  currentPage = 1
  totalItems: any

  currTab: any = 'BUY';
  responseDataArray: any = []
  feeColletion: any;
  tradeStatus: any;
  country: any;
  paymentType: any;
  type: any;

  constructor(public service: MainService, private datePipe: DatePipe) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
    //this.getCustomerLogs()
    // this.getAllTransactionHistory()
    this.selectTab('BUY')
  }

  // from/to date validation
  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }


  selectTab(tab) {
    this.currentPage = 1
    this.totalItems = 0
    this.currTab = tab
    this.searchByEmail = ''
    this.fromDate = ''
    this.twoDate = ''
    this.calender = { todate: '', fromdate: '' }
    this.responseDataArray = []
    switch (this.currTab) {
      case 'BUY':
        this.getDipositeHistory();
        break;
      case 'SELL':
        this.getWithdrawalHistory();
        break;
      case 'FEE':
        this.getFeeCollection();
        break
    }
  }


  search() {
    if (this.searchByEmail || this.fromDate || this.twoDate) {
      switch (this.currTab) {
        case 'BUY':
          this.getDipositeHistory();
          break;
        case 'SELL':
          this.getWithdrawalHistory();
          break;
        case 'FEE':
          this.getFeeCollection();
          break
      }
    } else {
      console.log('Please enter the fields for search.')
    }
  }


  reset() {
    if (this.searchByEmail || this.fromDate || this.twoDate) {
      this.searchByEmail = ''
      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      switch (this.currTab) {
        case 'BUY':
          this.getDipositeHistory();
          break;
        case 'SELL':
          this.getWithdrawalHistory();
          break;
        case 'FEE':
          this.getFeeCollection();
          break
      }
    }
  }


  pagination(page) {
    this.currentPage = page
    switch (this.currTab) {
      case 'BUY':
        this.getDipositeHistory();
        break;
      case 'SELL':
        this.getWithdrawalHistory();
        break;
      case 'FEE':
        this.getFeeCollection();
        break
    }
  }



  getDipositeHistory() {
    // let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${(this.currentPage - 1) + ('&pageSize=' + this.itemsPerPage)
    //   + ('&txnType=' + this.currTab)
    //   + (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    // let url = `p2p-exchange/search-and-filters-trade-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${(this.tradeStatus ? ('&tradeStatus=' + this.tradeStatus) : '')
    // + (this.country ? ('&country=' + this.country) : '') + (this.paymentType ? ('&paymentType=' + this.paymentType) : '') + (this.type ? ('&type=' + this.currTab) : '') +
    // ((this.fromDate) ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    // this.service.showSpinner();
    let url=`p2p-exchange/admin/search-and-filters-trade-list?${this.country?`country=${this.country}`:''}
    &fromDate=${this.fromDate?this.fromDate:''}&page=${this.currentPage-1}&pageSize=${ this.itemsPerPage}
    &paymentType=${this.paymentType?this.paymentType:''}&toDate=${this.twoDate?this.twoDate:''}
    &tradeStatus=${this.tradeStatus?this.tradeStatus:''}&type=${this.currTab}`

    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.responseDataArray = res.data.list;
        this.totalItems = res['data'].totalCount
        this.service.toasterSucc(res['message'])
      } else {
        this.responseDataArray = []
        this.totalItems = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
      console.log(err)
    })
  }

  getWithdrawalHistory() {
    // let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${(this.currentPage - 1) + ('&pageSize=' + this.itemsPerPage)
    // + ('&txnType=' + this.currTab)
    // + (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    let url=`p2p-exchange/admin/search-and-filters-trade-list?${this.country?`country=${this.country}`:''}
    &fromDate=${this.fromDate?this.fromDate:''}&page=${this.currentPage-1}&pageSize=${ this.itemsPerPage}
    &paymentType=${this.paymentType?this.paymentType:''}&toDate=${this.twoDate?this.twoDate:''}
    &tradeStatus=${this.tradeStatus?this.tradeStatus:''}&type=${this.currTab}`
    this.service.showSpinner();

    this.service.get(url).subscribe((res: any) => {
      console.log("djsuffgy78sghdfijkuhgjv", res);
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.responseDataArray = res.data.list;
        this.totalItems = res['data'].totalCount
        this.service.toasterSucc(res['message'])
        console.log(res)
      } else {
        this.responseDataArray = []
        this.totalItems = 0
        this.service.toasterErr('Record Not Found')
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr('Record Not Found')
      console.log(err)
    })
  }

  getFeeCollection() {
    let url = `p2p-exchange/admin/search-and-filters-trade-list?page=${(this.currentPage - 1) + ('&pageSize=' + this.itemsPerPage)
    + (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`

    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.feeColletion =res.data.feeColletion
        this.responseDataArray = res.data.list;
        this.totalItems = res['data'].totalCount
        this.service.toasterSucc(res['message'])
      } else {
        this.responseDataArray = []
        this.totalItems = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
      console.log(err)
    })
  }

  // common export as excel for BUYe, withdrawal and fee-collection
  exportAsXLXS() {
    let dataArr = [];
    switch (this.currTab) {
      case 'BUY':
        this.responseDataArray.forEach((element) => {
          dataArr.push({
            'ID': element.userId ? element.userId : 'N/A',
            'Buyer': element.buyer ? element.buyer : 'N/A',
            'Seller': element.seller ? element.seller : 'N/A',
            'Coin': element.fiatCoin ? element.fiatCoin : 'N/A',
            'Amount': element.tradeAmount ? element.tradeAmount : 'N/A',
            'Trade Id': element.tradeId ? element.tradeId : 'N/A',
            'Payment Method': element.paymentType ? element.paymentType : 'N/A',
            'Date and Time': element.creationTime ? this.datePipe.transform(element.creationTime) : 'N/A',
          })
        })
        break;
      case 'SELL':
        this.responseDataArray.forEach((element) => {
          dataArr.push({
            'ID': element.userId ? element.userId : 'N/A',
            'Buyer': element.buyer ? element.buyer : 'N/A',
            'Seller': element.seller ? element.seller : 'N/A',
            'Coin': element.fiatCoin ? element.fiatCoin : 'N/A',
            'Amount': element.tradeAmount ? element.tradeAmount : 'N/A',
            'Payment Method': element.paymentType ? element.paymentType : 'N/A',
            'Date and Time': element.creationTime ? this.datePipe.transform(element.creationTime) : 'N/A',
          })
        })
        break;
      case 'FEE':
        this.responseDataArray.forEach((element) => {
          dataArr.push({
            'Id': element.tradingId ? element.tradingId : 'N/A',
            'Date': element.creationTime ? this.datePipe.transform(element.creationTime) : 'N/A',
            'Buyer': element.buyer ? element.buyer : 'N/A',
            'Seller': element.seller ? element.seller : 'N/A',
            'Crypto Amount': element.totalBTC ? element.totalBTC : 'N/A',
            'Fiat Amount': element.tradeAmount ? element.tradeAmount : 'N/A',
            'Fee': element.tradeFee ? element.tradeFee : 'N/A'
          })
        })
        break;
    }
    this.service.exportAsExcelFile(dataArr, 'Finance' + this.currTab.charAt(0).toUpperCase() + this.currTab.slice(1))
  }

  copyToClipboard(item) {

    this.service.toasterSucc('copied')
     document.addEventListener('copy', (e: ClipboardEvent) => {
       e.clipboardData.setData('text/plain', (item));
       e.preventDefault();
       document.removeEventListener('copy', null);
     });
     document.execCommand('copy');
   }
}
