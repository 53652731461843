import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {
  userId: any;
  editAdminForm: FormGroup;
  ipAddress: any;
  newArr: any = [];
  adminDetails: any
  adminPrivileges: any;

  // constructor(private http: HttpClient){
  //   this.http.get<{ip:string}>('https://jsonip.com')
  //   .subscribe( data => {
  //     console.log('th data', data);
  //     this.ipAddress = data
  //   })
  // }

  constructor(public param: ActivatedRoute, public service: MainService, public router: Router, private http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.userId = res.id;
      console.log("userId", res)
    })
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        console.log('th data', data);
        this.ipAddress = data.ip
        console.log("djfhgdj", this.ipAddress)
      })
  }

  ngOnInit() {
    this.editAdminForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'lastName': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'role': new FormControl('', Validators.required),
      'gender': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', Validators.required),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      userChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
    this.getProfile()
  }

  getProfile() {
    let dataa = {
      "primaryIdCommonPerRequest": this.userId
    }
    this.service.showSpinner();
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      console.log("get admin details==>", res)
      this.adminDetails = res['data']['staffDetails']
      this.editAdminForm.patchValue({
        firstName: this.adminDetails.firstName,
        lastName: this.adminDetails.lastName,
        email: this.adminDetails.email,
        gender: this.adminDetails.gender,
        role: this.adminDetails.role,
        phoneNumber: this.adminDetails.phoneNo
      })
      // console.log("hgfsjdhcfgf",this.adminDetails)
      this.adminPrivileges = res['data']['staffPrivileges']
      this.adminPrivileges.forEach(element => {
        if (element == 'DASHBOARD') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            dashboardChecked: true
          })
        } else if (element == 'HOT_COLD_LIMIT_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            hotWalletChecked: true
          })
        }
        else if (element == 'KYC_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            kycChecked: true
          })
        }
        else if (element == 'WALLET_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            walletChecked: true
          })
        }
        else if (element == 'STATIC_CONTENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            staticChecked: true
          })
        }
        else if (element == 'LOGS_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            logsChecked: true
          })
        }
        else if (element == 'TICKET_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            ticketChecked: true
          })
        }
        else if (element == 'TRADE_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            tradeChecked: true
          })
        }
        else if (element == 'DISPUTE_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            disputeChecked: true
          })
        }
        else if (element == 'STAFF_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            staffChecked: true
          })
        }
        else if (element == 'USER_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            userChecked: true
          })
        }
        else if (element == 'BANK_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            bankChecked: true
          })
        }
        else if (element == 'FEE_MANAGEMENT') {
          this.newArr.push(element);
          this.editAdminForm.patchValue({
            feeChecked: true
          })
        }
      });
      setTimeout(() => {
        this.service.hideSpinner();
      }, 2000)
    })
  }


  addStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.editAdminForm.value.email,
      "firstName": this.editAdminForm.value.firstName,
      "gender": this.editAdminForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editAdminForm.value.lastName,
      "location": "string",
      "phoneNo": this.editAdminForm.value.phoneNumber,
      "previlage": this.newArr,
      // "roleStatus": "ADMIN",
      "roleStatus": this.editAdminForm.value.role,
      "state": "string",
      "webUrl": "string",
      "userIdToUpdate": this.userId
    }
    console.log("jhgfcjkvdsfkjg", data)
    this.service.showSpinner();
    this.service.postApi('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      console.log("jhgfcjkvdsfkjg", res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc('Updated successfully.')
        this.router.navigate(['/admin-management'])
      } else {
        this.service.toasterErr(res.message)
      }
    }, (error: any) => {
      console.log(error)
      this.service.hideSpinner()
      this.service.toasterErr('Something went wrong.')
    })
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
    console.log("permission->", value, checked)
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      console.log(index)
      this.newArr.splice(index, 1)
      console.log("new arr->", this.newArr);
    }
  }

  cancel() {
    this.router.navigate(['/admin-management'])
  }

}
