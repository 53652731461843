import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  id: any;
  privacy: any;
  dataa: any=[];
  constructor(public service:MainService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(x=>{
    
      this.id= x['id'];
    })
    this.getListCode();
  }

  // Get List Code
  getListCode(){
    this.service.showSpinner();
    this.service.get('static/get-static-content?pageKey=Privacy Policy').subscribe(res=>{
    
      this.service.hideSpinner();
      if(res['status']== 200){
        var data = res['data'];
        this.dataa = res['data'];
        // this.privacy = data.filter(x=>(x.staticContentId == this.id))
        
      
      }
    }, err=>{
     
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access.');
      }else{
      this.service.toasterErr('Something went wrong.');
   }
    })
  }

  // Save Abou Us Functionality
  savePrivacy(){
    var apiReq = {
      "contentId": this.dataa.staticContentId,
     "pageData": this.dataa.pageData
   }
   this.service.showSpinner();
   this.service.post('static/admin/static-content/update-static-content',apiReq).subscribe(res=>{
   
     this.service.hideSpinner();
     if(res['status']== 200){
       this.getListCode();
      this.service.toasterSucc('Privacy policy updated successfully.')
     }else{
       this.service.toasterErr('Privacy policy updated successfully.')
     }
   }, err=>{
   
     this.service.hideSpinner();
     if(err['status']=='401'){
       this.service.onLogout();
       this.service.toasterErr('Unauthorized access.');
     }else{
     this.service.toasterErr('Something went wrong.');
  }
   })
   }

}
